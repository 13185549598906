import { createSelector } from 'reselect'
import _ from 'lodash'
import { LOGIN } from './constants'
import { PENDING, SUCCESS } from '../../reduxPromise'
import { getWebsiteConfig } from '../config/selectors'

export const getErrorMessage = state => (state.auth.error || {}).message
export const getStatus = state => state.auth.status
export const getAction = state => state.auth.action
export const getCurrentUser = state => (state.auth || {}).user
export const getIsLoggedIn = state => {
  const isRestored = _.get(state, 'auth.restoreStatus') === 'SUCCESS'
  return (state.auth || {}).user !== undefined && isRestored
}
export const getAccountIsLocked = state => _.chain(state)
  .get('auth.error.code')
  .eq('RA-02-02')
  .value()
export const getUserSelectedOrganisationId = state => (state.auth.user || {}).selectedOrganisationId
export const getUserSelectedRegionId = state => (state.auth.user || {}).selectedRegionId
export const getUserSelectedReceiverId = state => (state.auth.user || {}).selectedReceiverId
export const getLoggedInUserRole = state => (state.auth.user || {}).role
export const getUserOccupation = state => _.get(state, 'auth.user.professions[0].id')
export const getKeepMeLoggedIn = state => _.get(state, 'auth.keepMeLoggedIn')
export const getIsRestored = state => _.get(state, 'auth.restoreStatus') === 'SUCCESS'
export const getUserWatchlistProducts = state => _.get(state, 'auth.user.watchList')
export const getIsLoggedOut = state => _.get(state, 'auth.isLoggedOut')

export const getIsUserAdmin = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'isAdmin'))

export const getRoleValue = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'value'))

export const getIsUserPublic = createSelector([
  getRoleValue
], (roleValue) => roleValue === 6)

export const getLoggedInUsersPermissions = createSelector([
  getLoggedInUserRole,
  getIsUserAdmin
], (role, isAdmin) => {
  return {
    isAdmin,
    ..._.get(role, 'permissions', {})
  }
})

export const getHasUserManagement = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.userManagement', false))

export const getHasHigherUserAccess = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.higherUserAccess', true))

export const getHasCreateAck = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.createAck', false))

export const getHasConfigureNews = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureNews', false))

export const getHasConfigureOrgPermission = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureOrg', false))

export const getHasConfigureOrgFundamentalsPermission = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureOrgFundamentals', false))

export const getCanViewOrganisation = getHasConfigureOrgPermission

export const getCanViewAllReports = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewAllReports', false))

export const getCanEditReportConfig = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.editReportConfig', false))

export const getCanViewOrganisationReports = createSelector([
  getLoggedInUserRole,
  getCanViewAllReports
], (role, canViewAllReports) => (canViewAllReports || _.get(role, 'permissions.viewOrganisationReports', false)))

export const getCanViewOwnReports = createSelector([
  getLoggedInUserRole,
  getCanViewOrganisationReports
], (role, canViewOrganisationReports) => (canViewOrganisationReports || _.get(role, 'permissions.viewOwnReports', false)))

export const getCanEditFiles = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.editFiles', false))

export const getCanViewFiles = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewFiles', false))

export const getHasPlatformDetailsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.platformDetails', false))

export const getHasCreateNewsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.createNews', false))

export const getHasEditNewsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.updateNewsArticle', false))

export const getHasDeleteNewsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.deleteNews', false))

export const getHasMultipleOrganisations = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'multipleOrganisations', false))

export const getIsLoggingIn = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === LOGIN)
))

export const getHasViewOtherUsersNotifications = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewOtherUsersNotifications', false))

export const getUserOrganisationsOptions = createSelector([
  getCurrentUser
], user => (
  user ? user.organisations.mapz(organisation => ({
    label: organisation.name,
    value: organisation.id
  })) : []
))
export const getUserOrganisationRegionOptions = createSelector([
  getCurrentUser
], user => {
  return user ? user.organisations.map(organisation => ({
    label: organisation.name,
    value: `${organisation.id}_${organisation.regionId}_${organisation.receiverId}`
  })) : []
})
export const getUserOrganisations = createSelector([
  getCurrentUser
], user => (
  user ? user.organisations : []
))

export const getUserOrganisationNames = createSelector([
  getUserOrganisations
], (userOrganisations) => userOrganisations.map(organisation => organisation.name))

export const getUserOrganisationIds = createSelector([
  getUserOrganisations
], (userOrganisations) => userOrganisations.map(organisation => organisation.id))

export const getSelectedOrganisation = createSelector([
  getCurrentUser
], (user) => {
  return _.get(user, 'organisations', []).find(organisation => organisation.id === user.selectedOrganisationId)
})

export const getSelectedOrganisationInternalNewsFeedId = createSelector(
  [getSelectedOrganisation], (organisation) => {
    const internalFeed = _.find(organisation.newsFeeds, (feed) => feed.isInternalFeed)
    return _.get(internalFeed, 'id')
  })

export const getSelectedOrganisationThemeId = createSelector(
  [getSelectedOrganisation], (org) => {
    return _.get(org, 'themeId')
  })

const getOrganisationsHashmap = createSelector(
  [getUserOrganisations],
  organisations => {
    return (organisations || []).reduce(
      (hashmap, organisation) => {
        return {
          ...hashmap,
          [organisation.id]: organisation
        }
      }, {})
  }
)

export const getUserOrganisationFromId = (state, id) => {
  const organisationsHashMap = getOrganisationsHashmap(state)
  const organisation = organisationsHashMap[id] || {}
  return organisation
}

export const getActiveUsername = createSelector([
  getCurrentUser
], user => {
  return user && user.username
})

export const getActiveUserValue = createSelector([
  getCurrentUser
], currentUser => {
  return currentUser && currentUser.role.value
})

export const getActiveUserId = createSelector([
  getCurrentUser
], user => {
  return user && user.id
})

export const getActiveUserFullName = createSelector([
  getCurrentUser
], user => {
  return user && `${user.firstName} ${user.lastName}`
})

export const hasCompleteProfile = createSelector([
  getCurrentUser
], user => {
  return user && user.firstName && user.lastName
})

export const getUserInitialFormValues = createSelector([
  getCurrentUser,
  getWebsiteConfig
], (user = {}, config) => {
  const vigilanceHubOrganisationId = _.get(config, 'vigilanceHubOrganisationId')
  const userProfession = _.find(user.professions, profession => {
    return profession.organisationId === vigilanceHubOrganisationId
  }) || {}

  return _.pickBy({
    ..._.pick(user, [
      'id',
      'firstName',
      'lastName',
      'email',
      'title',
      'company',
      'houseNumberOrName',
      'address',
      'addressLineTwo',
      'city',
      'county',
      'postalCode',
      'telephone',
      'telephoneExtension'
    ]),
    professionId: userProfession.id
  }, _.identity)
})

export const getUserProfessionType = createSelector([
  getCurrentUser,
  getWebsiteConfig
], (user = {}, config) => {
  const vigilanceHubOrganisationId = _.get(config, 'vigilanceHubOrganisationId')
  const userProfession = _.find(user.professions, profession => {
    return profession.organisationId === vigilanceHubOrganisationId
  }) || {}

  return userProfession.type
})

export const getUserMigrationStatus = createSelector([
  getCurrentUser
], (user = {}) => {
  return _.get(user, 'passwordPolicy', {})
})

export const getUserPasswordPolicyIsValid = createSelector([
  getUserMigrationStatus
], (passwordPolicy) => {
  return _.get(passwordPolicy, 'isValid', true)
})

export const getKeepMeLoggedInStatus = createSelector([
  getKeepMeLoggedIn
], (keepMeLoggedIn) => {
  return keepMeLoggedIn
})

export const getUserToken = createSelector([
  getCurrentUser
], (user = {}) => {
  return _.get(user, 'token', null)
})

export const getUserDetails = createSelector([
  getCurrentUser
], (user = {}) => {
  return _.get(user, 'details', {})
})

export const getTestUserDetails = createSelector([
  getCurrentUser,
  getWebsiteConfig
], (user = {}, config) => {
  const website = _.get(config, 'websiteKey')
  return _.get(user, `details.test.${website}`, {})
})

export const getTestFormViewId = createSelector([
  getTestUserDetails
], (testConfig) => {
  return _.get(testConfig, 'formViewId')
})
